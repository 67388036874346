import React from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

const layout = ({ data }) => (
  <Layout>
    <article style={{ maxWidth: '1920px' }}>
      <HelmetDatoCms seo={data.datoCmsWork.seoMetaTags} />
      <div>
        <header>
          <h1 className="dib ml3 lh-title fw4">
            {data.datoCmsWork.title}
          </h1>
        </header>

        {data.datoCmsWork.gallery.map(({ fluid, alt }, index) => (
          <figure className="db mt0 mb4 mr3 ml3 bg-black-10" key={"figure-" + alt}>
            <Img
              fluid={fluid}
              alt={alt}
              key={alt}
              critical={index <= 1}
              loading={index <= 2 ? 'eager' : 'lazy'}
              fadeIn={false}
            />
            <figcaption className="bg-white pt2 f6 black-70">
              {alt}
            </figcaption>
          </figure>
        ))}
      </div>
    </article>
  </Layout>
)

export default layout;

export const query = graphql`
  query WorkQuery($slug: String!) {
    datoCmsWork(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      exhibition
      year
      gallery {
        alt
        fluid(maxWidth: 2000, imgixParams: { q: 90, chromasub: 444, minW: 1000 }) {
          aspectRatio
          ...GatsbyDatoCmsSizes_noBase64
        }
      }
    }
  }
`
